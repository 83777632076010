<template>
  <v-app>
    <v-card width="95%" class="mx-auto mt-5">
      <LocaleSwitcher />
      <v-card-title>
        {{ $t("sginIn.ログイン") }}
      </v-card-title>
      <v-form>
        <div class="signin ma-5">
          <v-text-field
            id="email"
            prepend-icon="mdi-account-circle"
            :label="$t('sginIn.メールアドレス')"
            v-model="name"
          />
          <v-text-field
            id="password"
            v-bind:type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            prepend-icon="mdi-lock"
            v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :label="$t('sginIn.パスワード')"
            v-model="password"
          />
          <v-alert
            v-model="alert"
            close-text="Close Alert"
            color="red accent-4"
            dark
            dismissible
            class="ma-5"
          >
            {{ error_message }}
          </v-alert>
          <v-btn id="submit" class="info" @click="doLogin">{{
            $t("sginIn.ログイン")
          }}</v-btn>
        </div>
      </v-form>
    </v-card>

    <v-card width="95%" class="mx-auto mt-5" v-if="clinic_id">
      <v-card-text>
        {{ $t("sginIn.初めてお使いになるお客様へ") }}
        <br />
        {{ $t("sginIn.新しいアカウントを作成しますか？") }}
        <br />
        <v-btn :to="`/signup/${clinic_id}`" id="singup" @click="doLogin">{{
          $t("sginIn.新規登録")
        }}</v-btn>
      </v-card-text>
    </v-card>

    <v-card width="95%" class="mx-auto mt-5">
      <v-card-text>
        {{ $t("sginIn.パスワードがわからない場合") }}
        <br />
        <v-expansion-panels class="mt-5">
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("sginIn.パスワード再設定")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                id="email"
                prepend-icon="mdi-account-circle"
                :label="$t('sginIn.メールアドレス')"
                v-model="email"
              />

              <v-alert
                v-model="reset_alert"
                close-text="Close Alert"
                color="orange accent-4"
                dark
                dismissible
                class="ma-5"
              >
                {{ reset_alert_message }}
              </v-alert>
              <v-btn id="submit" class="success" @click="reset_password">{{
                $t("sginIn.再設定メール送信")
              }}</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
import firebase from "firebase/app";
import "firebase/auth"; // Firebase Authentication モジュールのインポート

import LocaleSwitcher from "@/components/LocaleSwitcher";
export default {
  name: "login",
  components: { LocaleSwitcher },
  created: function () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("User is logged in:", user);
      } else {
        console.log("No user is logged in");
      }
    });
  },
  data() {
    return {
      showPassword: false,
      error_message: "",
      alert: false,
      name: "",
      password: "",
      email: "",
      reset_alert: false,
      reset_alert_message: "",
      user: null,
    };
  },
  props: {},
  computed: {
    clinic_id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async reset_password() {
      const actionCodeSettings = {
        url: "https://clinic-customer.web.app/login",
        handleCodeInApp: false,
      };
      firebase
        .auth()
        .sendPasswordResetEmail(this.email, actionCodeSettings)
        .then(
          () => {
            this.reset_alert = true;
            this.reset_alert_message =
              "ご登録のメールアドレスに再設定のリンクを送付いたしました。";
          },
          (err) => {
            this.reset_alert = true;
            (this.reset_alert_message =
              "ご登録のアドレスがございません。メールアドレスのご確認をお願いします。"),
              console.log(err);
          }
        );
    },
    async doLogin() {
      //IDの入力フォームのバリデーション
      if (!this.name) {
        this.error_message =
          "ログインできません。メールアドレスを入力してください。";
        this.alert = true;
        return;
      }
      if (!this.password) {
        this.error_message =
          "ログインできません。パスワードを入力してください。";
        this.alert = true;
        return;
      }
      try {
        const userCredential = await firebase
          .auth()
          .signInWithEmailAndPassword(this.name, this.password);
        console.log("User logged in:", userCredential.user);
      } catch (error) {
        console.error("Login error:", error.message);
      }
      if (this.clinic_id) {
        const userRef = await Firebase.firestore()
          .collection("Users")
          .doc(this.$store.getters.user.uid);
        await userRef.get().then((doc) => {
          if (doc.exists) {
            this.user = doc.data();
            this.user.id = doc.id;
            this.clinics = this.user.clinics;
          } else {
            console.log("No user document!");
          }
          this.error = true;
        });

        let clinics = this.user.clinics;
        let is_registered = false;
        if (clinics.length > 0) {
          for (const clinic of clinics) {
            console.log(clinic.name);
            if (clinic.clinic_id === this.clinic_id) {
              is_registered = true;
              break;
            }
          }
        }
        if (is_registered == true) {
          this.$router.push({
            name: "priscription_list",
            params: { id: this.clinic_id },
          });
        } else {
          this.$router.push({
            name: "patient_regster",
            params: { id: this.clinic_id },
          });
        }
      } else {
        this.$router.push({ name: "clinic_list" });
      }
    },
  },
};
</script>
